import navbarStyle from "./NavBar.module.css";
import bdpLogo from "../../assets/img/Banco-Logo.png";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { languageContext } from "../../Context/Context";


// const { mainmenu, buttoncalcular, hamburguer, hamburguerContainer, one } = navbarStyle;
export const NavBar = () => {
    const {language, changeLanguage} = useContext(languageContext);

  const [scrolled, setScrolled] = useState(false);
  const [menuState, setMenuState] = useState(false);
  const [laguageSelection, setLanguageSelection] = useState("41px");

  const menuRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const onScroll = () => {
      if (window.innerWidth >= 800) {
        if (window.scrollY < window.innerHeight) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      } else {
        // console.log("soy un movil");
        setScrolled(false);
      }
    };

    const onMouseMove = (e: any) => {
      if (window.innerWidth <= 800) {
        // console.log("soy un movil");
      } else {
        if (window.scrollY > 400) {
          if (e.clientY < 100) {
            setScrolled(true);
          } else {
            setScrolled(false);
          }
        } else {
          setScrolled(true);
        }
        // console.log("soy un desktop");
        // setScrolled(false);
      }
    };

    if (window.innerWidth <= 900) {
      setLanguageSelection("71px");
    } else {
      setLanguageSelection("41px");
    }

    window.addEventListener("mousemove", onMouseMove);

    return () => window.removeEventListener("scroll", onScroll);
  }, [window.innerWidth]);
  

  const handleClick = () => {
    setMenuState(false);
    if (window.innerWidth >= 800) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const handleClickMenu = () => {
    setMenuState(!menuState);
  };

  return (
    <>
      <div
        onClick={handleClickMenu}
        className={navbarStyle.hamburguerContainer}
        ref={menuRef}
      >
        <div
          className={`${navbarStyle.hamburguer} ${navbarStyle.one}`}
          style={{
            transform: menuState ? "rotate(45deg)" : "rotate(0deg)",
            top: menuState ? "16px" : "10px",
          }}
        ></div>
        <div
          className={`${navbarStyle.hamburguer} ${navbarStyle.two}`}
          style={{ opacity: menuState ? "0" : "1" }}
        ></div>
        <div
          className={`${navbarStyle.hamburguer} ${navbarStyle.three}`}
          style={{
            transform: menuState ? "rotate(-45deg)" : "rotate(0deg)",
            top: menuState ? "16px" : "22px",
          }}
        ></div>
      </div>
      <nav
        className={navbarStyle.mainmenu}
        style={{
          opacity: scrolled || menuState ? "1" : "0",
          top: menuState || scrolled ? "0px" : "-100vh",
          transition: "200ms ease-in-out",
        }}
      >
        <ul>
          <li>
            <Link to="/#home" onClick={handleClick}>
              <img src={bdpLogo} alt="Banca gamer" />
            </Link>
          </li>
          <li>
            <Link to="/#home" onClick={handleClick}>
              HOME
            </Link>
          </li>
          <li>
            <Link to="/#info" onClick={handleClick}>
              INFO
            </Link>
          </li>
          <li>
            <Link to="/Faqs" onClick={handleClick}>
              FAQS
            </Link>
          </li>
          <li>
            <Link
              to="/#videojuegos"
              onClick={handleClick}
              className={navbarStyle.buttoncalcular}
              style={{ backgroundColor: "black" }}
            >
              {language === "es" ? "CALCULADORA" : "CALCULATOR"}
            </Link>
          </li>
          
          <li>
          <div
            className={`${navbarStyle.buttoncalcular} ${navbarStyle.translate}`}
          >
            <div
              className={navbarStyle.translateSelection}
              style={{ left: language === "es" ? laguageSelection : "-1px" }}
            />
            <div
              className={navbarStyle.languageOption}
              onClick={() => {
                  changeLanguage("en");
                // changeLanguage("en");
              }}
              style={{
                color: language === "en" ? "black" : "white"
              }}
            >
              ENG
            </div>
            <div
              className={navbarStyle.languageOption}
              onClick={() => {
                changeLanguage("es");
                // changeLanguage("es");
              }}
              style={{
                color: language === "es" ? "black" : "white",
              }}
            >
              ESP
            </div>
            
          </div>
          </li>
        </ul>
      </nav>
    </>
  );
};
