import { GameOptions } from "../../components/GameGrid/GameOptions";
import styles from "./HomePage.module.css";
import imageSection from "../../assets/img/amarillar_22_year_old_cool_gamer_with_blue_hair_headphones_wi_58bb27c9-7619-4fdc-baa9-ef5184471b3c_1.jpg";
import imageLogo from "../../assets/img/logo-banca-gamer-resize.png";
import imageLogoEn from "../../assets/img/logo-gamer-loan.png";
import { ScrollToAnchor } from "../../components/NavBar/Navegacion";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { NavBar } from "../../components/NavBar/NavBar";
import { languageContext, lenguaje } from "../../Context/Context";

const HomePage = () => {
  const { language } = useContext(languageContext);

  const [showScrollDown, setShowScrollDown] = useState(true);
  const location = useLocation();
  useEffect(() => {
    const onScroll = () => {
      const percentageScroll =
        ((window.scrollY / window.innerHeight) * 100) / 4;

      if (percentageScroll <= 75) {
        setShowScrollDown(true);
      } else {
        setShowScrollDown(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    const videoContainer = document.getElementById("videoContainer");
    if (location.pathname === "/") {
      videoContainer!.style.display = "block";
    }
    return () => {
      if (videoContainer !== null) {
        videoContainer.style.display = "none";
      }
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div className="App">
      <ScrollToAnchor />
      {/* <NavBarTest/> */}
      <NavBar />

      <header className="App-header" id="home">
        <img
          src={language === lenguaje.es ? imageLogo : imageLogoEn}
          alt="La banca gamer"
        />
        <p className={styles.subtitle}>
          {language === lenguaje.es
            ? "CONVIERTE HORAS DE DIVERSIÓN EN UN CRÉDITO BANCARIO"
            : "Transform the value of your video game accounts into a bank loan"}
        </p>

        <a href="#videojuegos" className={styles.buttoncalcular}>
          {language === lenguaje.es ? "CALCULAR AHORA" : "ESTIMATE NOW"}

          <span className={`material-symbols-outlined ${styles.buttonarrow}`}>
            navigate_next
          </span>
        </a>
        {showScrollDown === false ? null : (
          <div className={styles.scrolldown} style={{}}>
            <span className={`material-symbols-outlined ${styles.scrollicon}`}>
              arrow_downward
            </span>
            <p>SCROLL DOWN</p>
          </div>
        )}
      </header>
      <section id="info">
        <div className={styles.sectiontwocontainer}>
          <div className={styles.stack}>
            <h2 className={styles.stacktitle}>
              {language === lenguaje.es ? "SUBE AL" : "RISE TO"}
              <br />
              {language === lenguaje.es ? "SIGUIENTE" : "THE NEXT"}
              <br />
              {language === lenguaje.es ? "NIVEL" : "LEVEL"}
            </h2>
            <span className={`material-symbols-outlined ${styles.stackicon}`}>
              north_east
            </span>
            <p className={styles.pcontent}>
              {language === lenguaje.es
                ? "Usa nuestra calculadora gamer y descubre el valor de todos los skins, logros y otros coleccionables asociados a tu cuenta, que podrás utilizar como garantía para tu próximo crédito."
                : "Use our gamer calculator and discover the value of all the skins, achievements and other collectibles associated with your account, which you can use as collateral for your next loan."}

              <br />
              <br />
              {language === lenguaje.es
                ? "Un crédito accesible y sencillo para todos los que sabemos lo duro que es ganar un Battle Royale."
                : "An accessible and simple loan for all of us who know how hard it is to win a Battle Royale."}
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className={styles.sectiontwocontainer}>
          <h2 className={styles.sectiontwotitle}>
            {language === lenguaje.es ? "VALORAMOS" : "WE VALUE"}

            <br />
            {language === lenguaje.es ? "TU JUEGO" : "YOUR GAME"}
          </h2>
          <div className={styles.sectiontwocontentrow}>
            <div className={styles.sectiontwocontent}>
              <p>
                {language === lenguaje.es
                  ? "Ya sea que estés buscando financiamiento para tu próximo negocio o mejorar tu set-up de juegos, estamos aquí para ayudarte en ese upgrade."
                  : "Whether you are looking for financing your next business or improving your gaming setup, we are here to help you with that upgrade."}
              </p>
              <p>
                {language === lenguaje.es
                  ? "Únete a nuestra comunidad gamer y haz un power up a tus finanzas."
                  : "Join our gaming community and power up your finances."}
              </p>
            </div>
            <a href="#videojuegos" className={styles.buttoncalcular}>
              {language === lenguaje.es ? "CALCULAR AHORA" : "ESTIMATE NOW"}

              <span
                className={`material-symbols-outlined ${styles.buttonarrow}`}
              >
                navigate_next
              </span>
            </a>
          </div>
        </div>
      </section>

      <section>
        <div className={styles.sectionBackground}>
          <img src={imageSection} alt="" />
        </div>
        <div className={styles.stack}>
          {language === lenguaje.es ? 
          <h2 className={styles.stacktitle}>
          CONOCE
          <br />
          MÁS DE
          <br />
          BANCA
          <br />
          GAMER
        </h2> : <h2 className={styles.stacktitle}>
            LEARN
            <br />
            MORE
            <br />
            ABOUT
            <br />
            GAMER
            <br />
            LOAN
          </h2>}
          
          
          <div className={`material-symbols-outlined ${styles.stackicontwo}`}>
            north_east
          </div>
          <Link
            to="/Faqs"
            className={`${styles.buttoncalcular} ${styles.conocemas}`}
          >
            {language === lenguaje.es ? "CONOCE MÁS" : "LEARN MORE"}
            <span className={`material-symbols-outlined ${styles.buttonarrow}`}>
              navigate_next
            </span>
          </Link>
        </div>
      </section>

      <section id="videojuegos" className={styles.sectionGamesOptions}>
        <GameOptions />
        <div
        className="footer-home"
      >
        COPYRIGHT © 2024 BANCO DEL PACÍFICO
      </div>
      </section>
    </div>
  );
};

export default HomePage;
