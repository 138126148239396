import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { Dialog } from "../../components/Dialog/Dialog";
import InputText from "../../components/InputText/InputText";
import { NavBar } from "../../components/NavBar/NavBar";
import { Titular } from "../../components/Titular/Titular";
import { rocketLeague } from "../../data/ValorDeItems";

export const RocketLeague = () => {
  // Estados calculadora
  const [cotizacion, setCotizacion] = useState(0);
  const [tipoBoton, setTipoBoton] = useState(3);
  const [openDialog, setOpendialog] = useState(false);
  // Estados juego
  const [carros, setCarros] = useState(0);
  const [nivel, setNivel] = useState(0);
  const [recompensasT7, setRecompensasT7] = useState(false);
  const [titulosCG, setTitulosCG] = useState(false);
  let valorDeCuenta =
    rocketLeague.carros * carros +
    rocketLeague.nivel * nivel +
    (recompensasT7 ? rocketLeague.recompensasT7 : 0) +
    (titulosCG ? rocketLeague.titulos : 0);
  const nombreJuego = "Rocket League";

  const handleClick = () => {
    let val: number = valorDeCuenta / 100;
    val > cotizacion
      ? addAnimation(val)
      : val < cotizacion
      ? substracAnimation(val)
      : console.log("no cambiar");
    if (val > 0) {
      setTipoBoton(2);
    } else {
      setTipoBoton(3);
    }
  };

  // animacion de calculo de valores cuando suma
  const addAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result < resultado) {
        result = result + 0.13;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  // animacion de calculo de valores cuando resta
  const substracAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result > resultado) {
        result = result - 0.013;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  const enviarCuenta = () => {
    setOpendialog(true);
  };
  const location = useLocation();
  useEffect(() => {
    const videosCalculadora = document.getElementById("videoCalculadoras");
    if (location.pathname !== "/" && location.pathname !== "/Faqs") {
      videosCalculadora!.style.display = "block";
    }
    return () => {
      if (videosCalculadora !== null) {
        videosCalculadora.style.display = "none";
      }
    };
  });
  return (
    <section className="calculadora">
      <NavBar />
      <div className="form-container calculadora-padding">
        <div style={{ height: "100px" }}></div>
        <Titular cotizacion={cotizacion} titular={nombreJuego} />
        <span style={{ height: "60px" }}></span>
        <div className="grid">
          <InputText
            etiqueta={"Carros"}
            valor={carros}
            setValue={setCarros}
            aumento={1}
          />
          <InputText
            etiqueta={"Nivel"}
            valor={nivel}
            setValue={setNivel}
            aumento={1}
          />
          <div className="input-card">
            <label>RECOMPENSAS CG DE LA TEMPORADA 7</label>
            <div className="outlined-radio">
              <input
                type="radio"
                id="recompensasT7"
                name="recompensasT7"
                value="recompensasT7"
                checked={recompensasT7}
                onChange={() => {
                  setRecompensasT7(true);
                }}
              />
              <label htmlFor="recompensasT7">Si</label>
            </div>
            <div className="outlined-radio">
              <input
                type="radio"
                id="norecompensasT7"
                name="norecompensasT7"
                value="norecompensasT7"
                checked={!recompensasT7}
                onChange={() => {
                  setRecompensasT7(false);
                }}
              />
              <label htmlFor="norecompensasT7">No</label>
            </div>
          </div>
          <div className="input-card">
            <label>TÍTULOS CG</label>
            <div className="outlined-radio">
              <input
                type="radio"
                id="titulosgc"
                name="titulosgc"
                value="titulosgc"
                checked={recompensasT7}
                onChange={() => {
                  setTitulosCG(true);
                }}
              />
              <label htmlFor="titulosgc">Si</label>
            </div>
            <div className="outlined-radio">
              <input
                type="radio"
                id="notitulosgc"
                name="notitulosgc"
                value="notitulosgc"
                checked={!recompensasT7}
                onChange={() => {
                  setTitulosCG(false);
                }}
              />
              <label htmlFor="notitulosgc">No</label>
            </div>
          </div>
        </div>
        <div className="calculadora-buttons">
          <ButtonBancaGamer
            ruta=""
            action={handleClick}
            label="CALCULAR VALOR"
            tipo={2}
          />
          <ButtonBancaGamer
            ruta=""
            action={enviarCuenta}
            label="ENVIAR"
            tipo={tipoBoton}
          />
        </div>
        <div className="footer">COPYRIGHT © 2024 BANCO DEL PACÍFICO</div>
      </div>
      <Dialog
        openDialog={openDialog}
        closeDialog={setOpendialog}
        juego={nombreJuego}
        valor={cotizacion}
      />
    </section>
  );
};
