import { Link } from "react-router-dom";
import buttonStyle from "../../pages/Home/HomePage.module.css";
type props = {
  ruta: string;
  action: Function;
  label: string;
  tipo: number;
};
/** # Boton de aplicacion
 * funciona como ruteador si tiene una ruta valida, caso contrario poner
* campo de ruta como un string vacio.
* ## tipos de botones
* - 1 tipo ruta
* - 2 tipo accion
* - 3 tipo deshabilitado 
*/
export const ButtonBancaGamer = ({ ruta, action, label, tipo }: props) => {
  const {
    buttoncalcular,
    buttonarrow,
  } = buttonStyle;

  const determinateButton = (tipoBoton: number) => {
    switch (tipoBoton) {
      case 1: return <Link to={ruta!} className={buttoncalcular}>
        {label}
        <span className={`material-symbols-outlined ${buttonarrow}`}>
          navigate_next
        </span>
      </Link>;
      case 2: return <button className={buttoncalcular} onClick={() => action()}>
        {label}
        <span className={`material-symbols-outlined ${buttonarrow}`}>
          navigate_next
        </span>
      </button>;
      case 3: return <button className={buttoncalcular} onClick={() => action()} disabled>
      {label}
      <span className={`material-symbols-outlined ${buttonarrow}`}>
        navigate_next
      </span>
    </button>;
     default: return <div></div>
    }
  }
  return determinateButton(tipo);
};
