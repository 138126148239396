import "./ListTile.css";
import { Client, Language } from "fnapicom";
import { CosmeticosFortnite, Variant } from "../../types";
import { useState } from "react";
import { ColorRareza } from "../../utils/GetColorFortnite";

type props = {
  cosmetico: CosmeticosFortnite;
  addItem: Function;
  removeItem: Function;
  itemsDestacados: CosmeticosFortnite[];
};

const ListTile = ({
  cosmetico,
  addItem,
  removeItem,
  itemsDestacados,
}: props) => {
  const [destacado, setDestacado] = useState(false);

  const seleccionado = itemsDestacados.some((item: CosmeticosFortnite) => {
    return item.id === cosmetico.id;
  });

  let handleChange = (e: any) => {
    setDestacado(!destacado);
    if(!seleccionado){
        addItem(cosmetico);
    } else{
        removeItem(cosmetico);
    }
        
  };

  return (
    <div className="listTile">
      <img
        src={cosmetico.images.icon}
        className="leading"
        style={{ backgroundColor: ColorRareza(cosmetico.rarity.value) }}
      />
      <span style={{ width: "20px" }}></span>
      <div className="title">
        <span>{cosmetico.name}</span>
        {/* <div className="subtitle">
          <span>{cosmetico.description}</span>
        </div> */}
      </div>
      {seleccionado ? (
        <span
          className="icon-button material-symbols-outlined"
          onClick={handleChange}
        >
          remove
        </span>
      ) : (
        <span
          className="icon-button material-symbols-outlined"
          onClick={handleChange}
        >
          add
        </span>
      )}
    </div>
  );
};
export default ListTile;
