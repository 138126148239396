import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { NavBar } from "../../components/NavBar/NavBar";
import { Titular } from "../../components/Titular/Titular";
import InputText from "../../components/InputText/InputText";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { Dialog } from "../../components/Dialog/Dialog";
import { valorant } from "../../data/ValorDeItems";
import { languageContext, lenguaje } from "../../Context/Context";

export const Valorant = () => {
  const { language } = useContext(languageContext);
  const [cotizacion, setCotizacion] = useState(0);
  const [tipoBoton, setTipoBoton] = useState(3);
  const [openDialog, setOpendialog] = useState(false);
  // Estados juego
  const [rango, setRango] = useState(0);
  const [agentes, setAgentes] = useState(0);
  const [riotPoints, setRiotPoints] = useState(0);
  const [valorantPoints, setValorantPoints] = useState(0);
  const [skins, setSkins] = useState(0);
  const [armas, setArmas] = useState(0);
  const valorInicial = 0;
  const nombreJuego = "Valorant"

  let valorDeCuenta = (rango * valorant.rango) + (agentes * valorant.agentes) + (riotPoints * valorant.riotPoints) + (valorantPoints * valorant.valorantPoints)+ (skins * valorant.skins) + (armas * valorant.armas) +  valorInicial;

  const handleClick = () => {
    let val: number = valorDeCuenta / 100;
    val > cotizacion
      ? addAnimation(val)
      : val < cotizacion
        ? substracAnimation(val)
        : console.log("no cambiar");

    if (val > 0) {
      setTipoBoton(2);
    } else {
      setTipoBoton(3);
    }
  };

  // animacion de calculo de valores cuando suma
  const addAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result < resultado) {
        result = result + 0.13;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  // animacion de calculo de valores cuando resta
  const substracAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result > resultado) {
        result = result - 0.013;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  const enviarCuenta = () => {
    setOpendialog(true);
  };
  const location = useLocation();
  useEffect(() => {
    const videosCalculadora = document.getElementById("videoCalculadoras");
    if (location.pathname !== "/" && location.pathname !== "/Faqs") {
      videosCalculadora!.style.display = "block";
    }
    return () => {
      if (videosCalculadora !== null) {
        videosCalculadora.style.display = "none";
      }
    };
  });
  return (
    <section className="calculadora">
      <NavBar />
      <div className="form-container calculadora-padding">
        <div style={{ height: "100px" }}></div>
        <Titular cotizacion={cotizacion} titular={nombreJuego} />
        <span style={{ height: "60px" }}></span>
        <div className="grid">
        <InputText
            etiqueta={language === lenguaje.es ? "RANGO" :"RANK"}
            valor={rango}
            setValue={setRango}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "AGENTES" : "AGENTS"}
            valor={agentes}
            setValue={setAgentes}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "RIOT POINTS" : "RIOT POINTS"}
            valor={riotPoints}
            setValue={setRiotPoints}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "valorant points" : "VALORANT POINTS"}
            valor={valorantPoints}
            setValue={setValorantPoints}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "skins" : "SKINS"}
            valor={skins}
            setValue={setSkins}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "ARMAS" : "WEAPONS"}
            valor={armas}
            setValue={setArmas}
            aumento={1}
          />
        </div>
        <div className="calculadora-buttons">
          <ButtonBancaGamer
            ruta=""
            action={handleClick}
            label={language === lenguaje.es ? "CALCULAR VALOR" : "ESTIMATE NOW"}
            tipo={2}
          />
          <ButtonBancaGamer
            ruta=""
            action={enviarCuenta}
            label={language === lenguaje.es ? "ENVIAR" : "SEND"}
            tipo={tipoBoton}
          />
        </div>
        <div className="footer">COPYRIGHT © 2024 BANCO DEL PACÍFICO</div>
      </div>
      <Dialog openDialog={openDialog} closeDialog={setOpendialog} juego={nombreJuego} valor={cotizacion} />
    </section>
);
};
