import { NavBar } from "../../components/NavBar/NavBar";
import { useContext, useEffect, useState } from "react";
import { Titular } from "../../components/Titular/Titular";
import InputText from "../../components/InputText/InputText";
import { Dialog } from "../../components/Dialog/Dialog";
import { clashOfClansValores } from "../../data/ValorDeItems";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { useLocation } from "react-router-dom";
import { languageContext, lenguaje } from "../../Context/Context";

export const ClashOfClansCalculator = () => {
  const { language } = useContext(languageContext);

  // Estados calculadora
  const [cotizacion, setCotizacion] = useState(0);
  const [tipoBoton, setTipoBoton] = useState(3);
  const [openDialog, setOpendialog] = useState(false);
  // Estados juego
  const [townHallLevel, setTownHallLevel] = useState(0);
  const [level, setAccountLevel] = useState(0);
  const [barbarianKingLevel, setBarbarianKingLevel] = useState(0);
  const [nivelDeLuchadoraReal, setNivelDeLuchadoraReal] = useState(0);
  const [archerQueenLevel, setArcherQueenLevel] = useState(0);
  const [grandWarden, setGrandWarden] = useState(0);
  const [wallLevel, setWallLevel] = useState(0);
  const [gemas, setGemas] = useState(0);
  const [numBuilders, setNumBuilders] = useState(0);
  const nombreJuego = "Clash of Clans";
  const [showScrollDown, setShowScrollDown] = useState(true);

  let valorDeCuenta =
    clashOfClansValores.inicial +
    townHallLevel * clashOfClansValores.TownHallLevel +
    level * clashOfClansValores.Level +
    barbarianKingLevel * clashOfClansValores.BarbarianKingLevel +
    nivelDeLuchadoraReal * clashOfClansValores.Attack +
    archerQueenLevel * clashOfClansValores.ArcherQueenLevel +
    grandWarden * clashOfClansValores.GrandWarder +
    wallLevel * clashOfClansValores.WallLevel +
    gemas * clashOfClansValores.Gemas +
    numBuilders * clashOfClansValores.numBuilders;

  const handleClick = () => {
    let val: number = valorDeCuenta / 100;
    val > cotizacion
      ? addAnimation(val)
      : val < cotizacion
        ? substracAnimation(val)
        : console.log("no cambiar");
    if (val > 0) {
      setTipoBoton(2);
    } else {
      setTipoBoton(3);
    }
  };

  // animacion de calculo de valores cuando suma
  const addAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result < resultado) {
        result = result + 0.13;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  // animacion de calculo de valores cuando resta
  const substracAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result > resultado) {
        result = result - 0.013;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  const enviarCuenta = () => {
    setOpendialog(true);
  };
  const location = useLocation();
  useEffect(() => {
    const videosCalculadora = document.getElementById("videoCalculadoras");
    if (location.pathname !== "/" && location.pathname !== "/Faqs") {
      videosCalculadora!.style.display = "block";
    }
    return () => {
      if (videosCalculadora !== null) {
        videosCalculadora.style.display = "none";
      }
    };
  });
  return (
    <section className="calculadora">
      <NavBar />
      <div className="form-container calculadora-padding">
        <div style={{ height: "100px" }}></div>
        <Titular cotizacion={cotizacion} titular={nombreJuego} />
        <div className="grid">
          <InputText
            etiqueta={language === lenguaje.es ? "Nivel del ayuntamiento" : "TOWN HALL LEVEL" }
            valor={townHallLevel}
            setValue={setTownHallLevel}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "Nivel de experiencia" : "ACCOUNT LEVEL"}
            valor={level}
            setValue={setAccountLevel}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "Nivel del rey bárbaro" : "BARBARIAN KING LEVEL"}
            valor={barbarianKingLevel}
            setValue={setBarbarianKingLevel}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "Nivel de luchadora Real" : "ROYAL CHAMPION LEVEL"}
            valor={nivelDeLuchadoraReal}
            setValue={setNivelDeLuchadoraReal}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "Nivel de la reina arquera" : "ARCHER QUEEN LEVEL"}
            valor={archerQueenLevel}
            setValue={setArcherQueenLevel}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "Nivel del gran centinela" : "GRAND WARDEN LEVEL"}
            valor={grandWarden}
            setValue={setGrandWarden}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "nivel del muro" : "WALL LEVEL"}
            valor={wallLevel}
            setValue={setWallLevel}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "GEMAS" : "GEMS"}
            valor={gemas}
            setValue={setGemas}
            aumento={100}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "constructores" : "BUILDERS"}
            valor={numBuilders}
            setValue={setNumBuilders}
            aumento={1}
          />
        </div>
        <div className="calculadora-buttons">
          <ButtonBancaGamer
            ruta=""
            action={handleClick}
            label={language === lenguaje.es ? "CALCULAR VALOR" : "ESTIMATE NOW"}
            tipo={2}
          />
          <ButtonBancaGamer
            ruta=""
            action={enviarCuenta}
            label={language === lenguaje.es ? "ENVIAR" : "SEND"}
            tipo={tipoBoton}
          />
        </div>
        <div className="footer">COPYRIGHT © 2024 BANCO DEL PACÍFICO</div>
      </div>
      <Dialog
        openDialog={openDialog}
        closeDialog={setOpendialog}
        juego={nombreJuego}
        valor={cotizacion}
      />
    </section>
  );
};
