import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { Dialog } from "../../components/Dialog/Dialog";
import InputText from "../../components/InputText/InputText";
import { NavBar } from "../../components/NavBar/NavBar";
import { Titular } from "../../components/Titular/Titular";
import { freefire } from "../../data/ValorDeItems";
import { languageContext, lenguaje } from "../../Context/Context";
export const FreeFire = () => {
  const { language } = useContext(languageContext);
  // Estados calculadora
  const [cotizacion, setCotizacion] = useState(0);
  const [tipoBoton, setTipoBoton] = useState(3);
  const [openDialog, setOpendialog] = useState(false);
  // Estados juego
  const nombreJuego = "Free Fire";

  const [accountAge, setAccountAge] = useState(0);
  const [nivel, setNivel] = useState(0);
  const [trajes, setTrajes] = useState(0);
  const [armas, setArmas] = useState(0);
  const [maxEvo, setMaxEvo] = useState(0);

  let valorDeCuenta = ((accountAge * freefire.accountAge) + (nivel * freefire.nivel) + (trajes * freefire.trajes) + (armas * freefire.armas) + (maxEvo * freefire.maxEvo));

  const handleClick = () => {
    let val: number = valorDeCuenta / 100;
    val > cotizacion
      ? addAnimation(val)
      : val < cotizacion
        ? substracAnimation(val)
        : console.log("no cambiar");

    if (val > 0) {
      setTipoBoton(2);
    } else {
      setTipoBoton(3);
    }
  };

  // animacion de calculo de valores cuando suma
  const addAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result < resultado) {
        result = result + 0.13;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  // animacion de calculo de valores cuando resta
  const substracAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result > resultado) {
        result = result - 0.013;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  const enviarCuenta = () => {
    setOpendialog(true);
  };

  const location = useLocation();
  const [showScrollDown, setShowScrollDown] = useState(true);
  useEffect(() => {
    const onScroll = () => {
      const percentageScroll =
        ((window.scrollY / window.innerHeight) * 100) / 4;

      if (percentageScroll <= 75) {
        setShowScrollDown(true);
      } else {
        setShowScrollDown(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    const videosCalculadora = document.getElementById("videoCalculadoras");
    if (location.pathname !== "/" && location.pathname !== "/Faqs") {
      videosCalculadora!.style.display = "block";
    }

    return () => {
      if (videosCalculadora !== null) {
        videosCalculadora.style.display = "none";
        window.removeEventListener("scroll", onScroll);
      }
    };
  });
  return <>
    <section className="calculadora">
      <NavBar />
      <div className="form-container calculadora-padding">
        <div style={{ height: "100px" }}></div>
        <Titular cotizacion={cotizacion} titular={nombreJuego} />
        <span style={{ height: "60px" }}></span>
        <div className="grid">
          <InputText
            etiqueta={language === lenguaje.es ? "Años de la cuenta" : "ACCOUNT AGE"}
            valor={accountAge}
            setValue={setAccountAge}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "Nivel" : "LEVEL"}
            valor={nivel}
            setValue={setNivel}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "Armas" : "WEAPONS"}
            valor={armas}
            setValue={setArmas}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "Trajes" : "COSTUMES"}
            valor={trajes}
            setValue={setTrajes}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "Max Evo" : "MAX EVO"}
            valor={maxEvo}
            setValue={setMaxEvo}
            aumento={1}
          />

        </div>
        <div className="calculadora-buttons">
          <ButtonBancaGamer
            ruta=""
            action={handleClick}
            label={language === lenguaje.es ? "CALCULAR VALOR" : "ESTIMATE NOW"}
            tipo={2}
          />
          <ButtonBancaGamer
            ruta=""
            action={enviarCuenta}
            label={language === lenguaje.es ? "ENVIAR" : "SEND"}
            tipo={tipoBoton}
          />
        </div>
        <div className="footer">COPYRIGHT © 2024 BANCO DEL PACÍFICO</div>
      </div>
      <Dialog
        openDialog={openDialog}
        closeDialog={setOpendialog}
        juego={nombreJuego}
        valor={cotizacion}
      />
    </section>
  </>
}