export const valores = {
  valorSkinComun: 1,
  valorSkinPocoComun: 2,
  valorSkinRara: 3,
  valorSkinEpica: 5,
  valorSkinLegendaria: 10,
  valorSkinMarvel: 200,
  valorSkinStartWars: 200,
};

export const clashOfClansValores = {
  // inicial: 4200,
  inicial: 0,
  TownHallLevel: 1,
  Level: 50,
  BarbarianKingLevel: 10,
  Attack: 10,
  ArcherQueenLevel: 10,
  GrandWarder: 10,
  WallLevel: 10,
  Gemas: 10,
  numBuilders: 10,
};

export const robloxValores = {
  // inicial: 1500 * 0,
  inicial: 0,
  robux: 1.25,
  limitedItems: 250,
  lettersIName: 50,
  yearsOld: 500,
};

export const minecraftValores = {
  accountAge: 110,
  mvp: 700,
  originalName: 800,
  hypixel: 600,
};

export const callOfDuty = {
  inicial: 0,
  level: 100,
  epicGuns: 100,
  characterEpics: 100,
  legendaryGun: 100,
  legendCharacter: 1000,
  codPoints: 100,
};

export const pokemonGo = {
  numPokemons: 50,
  level: 120,
  numShyniPokemons: 700,
  maxlevelPokemons: 500,
  age: 20,
  numLegendaryPokemons: 300,
};

export const leageOfLegends = {
  servers: [
    {
      value: "Brazil",
      label: "Brazil",
    },
    {
      value: "northAmerica",
      label: "northAmerica",
    },
  ],
  inicial: 250,
  champions: 40,
  skins: 35,
  influencePoints: 7 / 1000,
  riotPoints: 4 / 100,
  runePages: 35,
};

export const wow = {
  inicial: 0,
  leves60Characters: 15000,
  goldCoins: 20,
  epicMounts: 12000,
  raidingEpicItems: 13500,
  legendaryItems: 50000,
  maxLevelProfessions: 5000,
};

export const valorant = {
  rango: 0,
  agentes: 100,
  riotPoints: 100,
  valorantPoints: 100,
  skins: 100,
  armas: 1000,
};

export const eafc24 = {
  inicial: 0,
  cartasIntransferibles: 10,
  cartasIntransferiblesDoradas: 100,
  cartasRojasIntransferiblesFutChamp: 200,
  sbcsAltacalidad: 200,
  jugadoresDeObjetivos: 200,
  estilosDeQuimica: 50,
  contratosJugadoresTecnicos: 10,
  monedas: 10,
};
export const rocketLeague = {
  inicial : 300,
  carros : 40,
  nivel : 20,
  recompensasT7 : 1000,
  titulos : 1000
};
export const genshinImpact = {
  inicial : 300,
  rangoAventura : 10,
  personajes5Estrellas : 25,
  personajes4Estrellas : 15,
  protogemas : 5,
  destinoEntrelazado : 10,
  encuentroDelDestino : 15,
  cristales : 100
}

export const freefire ={
  accountAge : 100,
  nivel: 13,
  trajes: 42,
  armas: 40,
  maxEvo: 75
}
