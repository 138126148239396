import { useState } from "react";
import { GameItem } from "../../data/Games";
import { Row } from "../../components/Row/Row";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { writeGames } from "../../utils/SendMail";
import imgControl from "../../assets/img/controller-Icon.png";
import "./GamesSettings.css";

type props = {
  juego: GameItem;
};
export const GameInput = ({ juego }: props) => {
  // const valorActual: number = juego.value[juego.value.length - 1];
  const [nuevoValor, setNuevoValor] = useState(juego.value[juego.value.length - 1]);
  const [valorActual, setValorActual] = useState(juego.value[juego.value.length - 1]);

  function handleChange(){
    setValorActual(nuevoValor);
  }

  function pushToDB(){
    handleChange();
    juego.value.push(nuevoValor);
    writeGames(juego);
  }

  return (
    <div className="grid-items">
      <center>
        <Row>
          <img src={imgControl} alt="" />
          <div className="header-videojuego">
            <b>Videojuego</b>
            <h2 style={{ fontSize: "24px" }}>{juego.name}</h2>
          </div>
        </Row>
        <div className="value-container">
          <div className="value-estimate">
            <span className="valor-estimado">${valorActual}</span>
          <p className="label-value-item">Valor actual de mercado</p>
          </div>
        </div>
        <input
          className="input-items"
          type="number"
          min="10"
          max="10000"
          placeholder="Agregar valor"
          name={juego.gameid}
          id={juego.gameid}
          minLength={5}
          style={{ width: "200px" }}
          value={nuevoValor}
          onChange={(e)=>setNuevoValor(parseInt(e.target.value))}
        />
        <ButtonBancaGamer
          ruta=""
          action={() => {
            pushToDB();
          }}
          label="Enviar"
          tipo={2}
        />
      </center>
    </div>
  );
};
