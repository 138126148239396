import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { Dialog } from "../../components/Dialog/Dialog";
import InputText from "../../components/InputText/InputText";
import { NavBar } from "../../components/NavBar/NavBar";
import { Titular } from "../../components/Titular/Titular";
import { eafc24 } from "../../data/ValorDeItems";
import { languageContext, lenguaje } from "../../Context/Context";

export const EAFC24 = () => {
  const { language } = useContext(languageContext);

  // Estados calculadora
  const [cotizacion, setCotizacion] = useState(0);
  const [tipoBoton, setTipoBoton] = useState(3);
  const [openDialog, setOpendialog] = useState(false);
  // Estados juego
  const [cartasIntransferibles, setCartasIntransferibles] = useState(0);
  const [cartasIntransferiblesDoradas, setCartasIntransferiblesDoradas] = useState(0);
  const [cartasRojasIntransferiblesFutChamp, setCartasRojasIntransferiblesFutChamp] = useState(0);
  const [sbcsAltaCalidadHechos, setSbcsAltaCalidadHechos] = useState(0);
  const [jugadoresDeObjetivos, setJugadoresDeObjetivos] = useState(0);
  const [estilosDeQuimica, setEstilosDeQuimica] = useState(0);
  const [contratosJugadoresTecnicos, setContratosJugadoresTecnicos] = useState(0);
  const [monedas, setMonedas] = useState(0);
  let valorDeCuenta =
    (eafc24.cartasIntransferibles * cartasIntransferibles) + (eafc24.cartasIntransferiblesDoradas * cartasIntransferiblesDoradas) + (eafc24.cartasRojasIntransferiblesFutChamp * cartasRojasIntransferiblesFutChamp) + (eafc24.sbcsAltacalidad * sbcsAltaCalidadHechos) + (eafc24.jugadoresDeObjetivos * jugadoresDeObjetivos) + (eafc24.estilosDeQuimica * estilosDeQuimica) + (eafc24.contratosJugadoresTecnicos * contratosJugadoresTecnicos) + (eafc24.monedas * monedas);
  const nombreJuego = "EA SPORTS FC 24";


  const handleClick = () => {
    let val: number = valorDeCuenta / 100;
    val > cotizacion
      ? addAnimation(val)
      : val < cotizacion
        ? substracAnimation(val)
        : console.log("no cambiar");
    if (val > 0) {
      setTipoBoton(2);
    } else {
      setTipoBoton(3);
    }
  };

  // animacion de calculo de valores cuando suma
  const addAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result < resultado) {
        result = result + 0.13;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  // animacion de calculo de valores cuando resta
  const substracAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result > resultado) {
        result = result - 0.013;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  const enviarCuenta = () => {
    setOpendialog(true);
  };
  const location = useLocation();
  useEffect(() => {
    const videosCalculadora = document.getElementById("videoCalculadoras");
    if (location.pathname !== "/" && location.pathname !== "/Faqs") {
      videosCalculadora!.style.display = "block";
    }
    return () => {
      if (videosCalculadora !== null) {
        videosCalculadora.style.display = "none";
      }
    };
  });
  return (
    <section className="calculadora calculadora-padding">
      <NavBar />
      <div className="form-container">
        <Titular cotizacion={cotizacion} titular={nombreJuego} />
        <span style={{ height: "60px" }}></span>
        <div className="grid">
          <InputText
            etiqueta={language === lenguaje.es ? "Cartas Intransferibles" : "UNTRADEABLE INFORM CARDS"}
            valor={cartasIntransferibles}
            setValue={setCartasIntransferibles}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "Cartas Intransferibles Doradas" : "UNTRADEABLE GOLD CARDS"}
            valor={cartasIntransferiblesDoradas}
            setValue={setCartasIntransferiblesDoradas}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "Cartas Rojas Intransferibles de Futchamp" : "UNTRADEABLE FUT CHAMP RED CARDS"}
            valor={cartasRojasIntransferiblesFutChamp}
            setValue={setCartasRojasIntransferiblesFutChamp}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "SBC’S DE ALTA CALIDAD HECHOS" : "HIGH QUALITY SBC’S DONE"}
            valor={sbcsAltaCalidadHechos}
            setValue={setSbcsAltaCalidadHechos}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "JUGADORES DE OBJETIVOS" : "OBJECTIVE PLAYERS"}
            valor={jugadoresDeObjetivos}
            setValue={setJugadoresDeObjetivos}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "ESTILOS DE QUÍMICA" : "CHEMISTRY STYLES"}
            valor={estilosDeQuimica}
            setValue={setEstilosDeQuimica}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "CONTRATOS DE JUGADORES Y TÉCNICOS" : "PLAYER AND MANAGER CONTRACTS"}
            valor={contratosJugadoresTecnicos}
            setValue={setContratosJugadoresTecnicos}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "MONEDAS" : "COINS"}
            valor={monedas}
            setValue={setMonedas}
            aumento={1}
          />
        </div>
        <div className="calculadora-buttons">
          <ButtonBancaGamer
            ruta=""
            action={handleClick}
            label={language === lenguaje.es ? "CALCULAR VALOR" : "ESTIMATE NOW"}
            tipo={2}
          />
          <ButtonBancaGamer
            ruta=""
            action={enviarCuenta}
            label={language === lenguaje.es ? "ENVIAR" : "SEND"}
            tipo={tipoBoton}
          />
        </div>
        <div className="footer">COPYRIGHT © 2024 BANCO DEL PACÍFICO</div>
      </div>
      <Dialog openDialog={openDialog} closeDialog={setOpendialog} juego={nombreJuego} valor={cotizacion} />
    </section>
  );
}