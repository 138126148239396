import "./CalculatorPage.css";
import InputText from "../../components/InputText/InputText";
import ListTile from "../../components/ListTile/ListTile";
import { useContext, useState } from "react";
import { valores } from "../../data/ValorDeItems";
import axios from "axios";
import { CosmeticosFortnite } from "../../types";
import { CardItemDestacado } from "../../components/CardItemDestacado/CardItemDestacado";
import { Row } from "../../components/Row/Row";
import { Dialog } from "../../components/Dialog/Dialog";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { NavBar } from "../../components/NavBar/NavBar";
import { Titular } from "../../components/Titular/Titular";
import { languageContext, lenguaje } from "../../Context/Context";


const RequestCosmetics = async (setter: Function, setStatus: Function) => {
  setStatus("loading");
  try {
    const res = await axios.get("https://fortnite-api.com/v2/cosmetics/br", {
      params: { language: "es" },
    });
    // console.log(res);
    if (res && res.status === 200) {
      setter(res.data.data);
      setStatus("fulfilled");
    }
  } catch (err) {
    setStatus("error");
  }
};
const itemsPerPage = 20;

export const Fortnite = () => {
  const { language } = useContext(languageContext);
  const [cotizacion, setCotizacion] = useState(0);
  const [pavos, setPavos] = useState(0);
  const [skinsComunes, setskinsComunes] = useState(0);
  const [skinsPocoComunes, setskinsPocoComunes] = useState(0);
  const [skinsRaras, setskinsRaras] = useState(0);
  const [skinsEpicas, setskinsEpicas] = useState(0);
  const [skinsLegendarias, setskinsLegendarias] = useState(0);
  const [skinsStarWars, setSkinsStarWars] = useState(0);
  const [skinsMarvel, setSkinsMarvel] = useState(0);
  const [skinsSerieIconos, setSkinsSerieIconos] = useState(0);
  const [openDialog, setOpendialog] = useState(false);
  const [tipoBoton, setTipoBoton] = useState(3);
  const [dataCosmetics, setDataCosmetics]: [
    [CosmeticosFortnite] | [],
    Function
  ] = useState([]);
  const nombreJuego = "Fortnite"

  const [fetchStatus, setFetchStatus] = useState("uninitialized");
  // const [page, setPage] = useState(1);
  const [page] = useState(1);
  // const [busqueda, setBusqueda] = useState("");
  const [busqueda] = useState("");
  const [itemsDestacados, setItemsDestacados]: [
    [CosmeticosFortnite] | [],
    Function
  ] = useState([]);

  /// Calculo de valors
  let valorDeCuenta =
    (pavos +
    skinsComunes * valores.valorSkinComun +
    skinsPocoComunes * valores.valorSkinPocoComun +
    skinsRaras * valores.valorSkinRara +
    skinsEpicas * valores.valorSkinEpica +
    skinsLegendarias * valores.valorSkinLegendaria +
    skinsMarvel * valores.valorSkinMarvel +
    skinsStarWars * valores.valorSkinStartWars)/100;

  // console.log("dataCosmetics:", dataCosmetics);

  const handleClick = () => {
    let val: number = valorDeCuenta;
    val > cotizacion
      ? addAnimation(val)
      : val < cotizacion
      ? substracAnimation(val)
      : console.log("no cambiar");

    if (val > 0) {
      setTipoBoton(2);
    } else {
      setTipoBoton(3);
    }
  };

  const enviarCuenta = () => {
    setOpendialog(true);
  };

  // animacion de calculo de valores cuando suma
  const addAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result < resultado) {
        result = result + 0.13;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };

  // animacion de calculo de valores cuando resta
  const substracAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result > resultado) {
        result = result - 0.013;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };

  //agregar elemento destacado
  const addFeaturedElement = (item: CosmeticosFortnite) => {
    setItemsDestacados([...itemsDestacados, item]);
    console.log(itemsDestacados.length);
  };
  //remover elemento destacado
  const removeFeaturedElement = (item: CosmeticosFortnite) => {
    const prueba = itemsDestacados.filter(
      (itema: CosmeticosFortnite) => itema.id !== item.id
    );
    setItemsDestacados(prueba);
    //const itemIndexToRemove = itemsDestacados.findIndex((itemDestacados: CosmeticosFortnite) => itemDestacados.id === item.id);
    //if (itemIndexToRemove !== -1) {
    //  const updatedItems = [...itemsDestacados];
    //  updatedItems.splice(itemIndexToRemove, 1);
    //    setItemsDestacados(updatedItems);
    //}
  };

  // let statusElement;
  // if (fetchStatus === "unitialized") {
  //   statusElement = <h1>pendiente</h1>;
  // } else if (fetchStatus === "fulfilled") {
  //   statusElement = <h1>se encontraron {dataCosmetics.length} elementos</h1>;
  // } else if (fetchStatus === "loading") {
  //   statusElement = <h1>cargando</h1>;
  // } else {
  //   statusElement = <h1>error</h1>;
  // }
  // busqueda
  let renderedCards;
  let outfitCosmetics;
  outfitCosmetics = dataCosmetics.filter((cosmetico: CosmeticosFortnite) =>
    cosmetico.type.value.includes("outfit")
  );

  const itemEnDestacados = (item: CosmeticosFortnite): boolean => {
    return itemsDestacados.some(
      (cosmetico: CosmeticosFortnite) => cosmetico.id === item.id
    );
  };
  //busqueda
  renderedCards = outfitCosmetics
    .filter((cosmetic: CosmeticosFortnite) =>
      busqueda.length > 0
        ? cosmetic.name.toLowerCase().includes(busqueda.toLowerCase())
        : false
    )
    .map((cosmetic: CosmeticosFortnite) => (
      <ListTile
        key={cosmetic.id}
        cosmetico={cosmetic}
        addItem={addFeaturedElement}
        removeItem={removeFeaturedElement}
        itemsDestacados={itemsDestacados}
      />
    ));

  const filtered = renderedCards.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (

      
      <section className="calculadora calculadora-padding">
      <NavBar />
        <div className="homebg">
          <video controls={false} autoPlay={true} loop muted>
            <source
              src="https://astrid-lab.com/public/fondo5.mp4"
              type="video/mp4"
            ></source>
          </video>
        </div>
        <div className="form-container">
          <Titular cotizacion={cotizacion} titular={nombreJuego} />

          {/* <div style={{height:"100px"}}></div>
          <input
            className="search-bar"
            type="text"
            placeholder="Ingresa el nombre de un articulo"
            value={busqueda}
            onChange={(e) => {
              setBusqueda(e.target.value);
            }}
          />
          
          <div className="items-search-container">
            {fetchStatus === "fulfilled" ? filtered : <Spinner />}
          </div> */}

          <span style={{ height: "60px" }}></span>
          <div className="grid">
            <InputText
              etiqueta={language === lenguaje.es ?"PAVOS":"V-BUCKS"}
              valor={pavos}
              setValue={setPavos}
              aumento={100}
            />
            <InputText
              etiqueta={language === lenguaje.es ?"Skins Comunes" : "COMMON SKINS"}
              valor={skinsComunes}
              setValue={setskinsComunes}
              aumento={1}
            />
            <InputText
              etiqueta={language === lenguaje.es ?"Skins Poco Comunes" : "UNCOMMON SKINS"}
              valor={skinsPocoComunes}
              setValue={setskinsPocoComunes}
              aumento={1}
            />

            <InputText
              etiqueta={language === lenguaje.es ?"Skins Raras":"RARE SKINS"}
              valor={skinsRaras}
              setValue={setskinsRaras}
              aumento={1}
            />

            <InputText
              etiqueta={language === lenguaje.es ?"Skins Épicas":"EPIC SKINS"}
              valor={skinsEpicas}
              setValue={setskinsEpicas}
              aumento={1}
            />
            <InputText
              etiqueta={language === lenguaje.es ?"Skins Legendarias" :"LEGENDARY SKINS"}
              valor={skinsLegendarias}
              setValue={setskinsLegendarias}
              aumento={1}
            />
            <InputText
              etiqueta={language === lenguaje.es ?"Skins de Marvel" : "MARVEL SKINS"}
              valor={skinsMarvel}
              setValue={setSkinsMarvel}
              aumento={1}
            />
            <InputText
              etiqueta={language === lenguaje.es ?"Skins de Star Wars" :"STAR WARS SKINS"}
              valor={skinsStarWars}
              setValue={setSkinsStarWars}
              aumento={1}
            />
            <InputText
              etiqueta={language === lenguaje.es ?"Skins de Serie de Iconos" : "ICON SERIES SKINS"}
              valor={skinsSerieIconos}
              setValue={setSkinsSerieIconos}
              aumento={1}
            />
          </div>
          <CardItemDestacado
            cosmeticosElegidos={itemsDestacados}
            remove={removeFeaturedElement}
          ></CardItemDestacado>
          <div className="calculadora-buttons">
            <ButtonBancaGamer
              ruta=""
              action={handleClick}
            label={language === lenguaje.es ? "CALCULAR VALOR" : "ESTIMATE NOW"}
              tipo={2}
            />
            <ButtonBancaGamer
              ruta=""
              action={enviarCuenta}
              label={language === lenguaje.es ? "ENVIAR" : "SEND"}
              tipo={tipoBoton}
            />
          </div>
          <div className="footer">COPYRIGHT © 2024 BANCO DEL PACÍFICO</div>
        </div>
          <Dialog openDialog={openDialog} closeDialog={setOpendialog} juego={nombreJuego} valor={cotizacion}/>
      </section>
  );
};
