const imgPokemonGo = "https://astrid-lab.com/public/pokemon-go.jpg";
const imgClashOfClans = "https://astrid-lab.com/public/clash-of-clans.jpg";
const imgRoblox = "https://astrid-lab.com/public/roblox.jpg";
const imgMinecraft = "https://astrid-lab.com/public/minecraft.jpg";
const imgCod = "https://astrid-lab.com/public/cod.jpg";
const imgFortnite = "https://astrid-lab.com/public/fortnite.jpg";
const imgLol = "https://astrid-lab.com/public/lol.jpg";
const imgWow = "https://astrid-lab.com/public/wow.jpg";
const imgEafc24 = "https://astrid-lab.com/public/eafc24.jpg";
const imgGenshinImpact = "https://astrid-lab.com/public/genshinimpact.jpg";
const imgValorant = "https://astrid-lab.com/public/valorant.jpg";
const imgRocketLeague = "https://astrid-lab.com/public/rocketLeague.jpg";
const imgFreeFire = "https://astrid-lab.com/public/freefire.jpg";

export type GameItem = {
  gameid: string;
  name: string;
  image: string;
  value: number[];
  destino: string;
}

export let Games = [
  {
    gameid: "callOfDuty",
    name: "Call of Duty",
    image: imgCod,
    value: [150, 100],
    destino: "/Call-of-duty",
  },
  {
    gameid: "clashOfClans",
    name: "Clash of Clans",
    image: imgClashOfClans,
    value: [150, 151],
    destino: "/Clash-of-Clans",
  },
  {
    gameid: "fortnite",
    name: "Fortnite",
    image: imgFortnite,
    value: [150, 151],
    destino: "/Fortnite",
  },
  {
    gameid: "leagueOfLegends",
    name: "League of Legends",
    image: imgLol,
    value: [150, 150],
    destino: "/Leage-of-Legends",
  },
  {
    gameid: "minecraft",
    name: "Minecraft",
    image: imgMinecraft,
    value: [150, 300],
    destino: "/Minecraft",
  },
  {
    gameid: "pokemonGo",
    name: "Pokemon Go",
    image: imgPokemonGo,
    value: [150, 148],
    destino: "/Pokemon-Go",
  },

  {
    gameid: "roblox",
    name: "Roblox",
    image: imgRoblox,
    value: [150, 155],
    destino: "/Roblox",
  },
  {
    gameid: "warcraft",
    name: "Warcraft",
    image: imgWow,
    value: [150, 300],
    destino: "/Warcraft",
  },
  {
    gameid: "eafc24",
    name: "EAFC24",
    image: imgEafc24,
    value: [150, 300],
    destino: "/EAFC24",
  },
  {
    gameid: "genshinImpact",
    name: "Genshin Impact",
    image: imgGenshinImpact,
    value: [150, 300],
    destino: "/GenshinImpact",
  },
  {
    gameid: "valorant",
    name: "Valorant",
    image: imgValorant,
    value: [150, 300],
    destino: "/Valorant",
  },
  // {
  //   gameid: "rocketLeague",
  //   name: "Rocket League",
  //   image: imgRocketLeague,
  //   value: [150, 300],
  //   destino: "/RocketLeague",
  // },
  {
    gameid: "freefire",
    name: "Free Fire",
    image: imgFreeFire,
    value: [150, 300],
    destino: "/Free-Fire",
  },
];