import { createContext, SetStateAction, useEffect, useState } from "react";
import { GameItem, Games } from "../data/Games";
import { readGames } from "../utils/SendMail";
export const GameItemListContext = createContext<GameItem[]>([]);

interface GameItemProviderProps {
  children: React.ReactNode; // children debe ser de tipo ReactNode
}

interface LanguageContextType {
  children: React.ReactNode;
}

export enum lenguaje {
  en = 'en',
  es = 'es'
}
export interface contextType {
  language: lenguaje,
  changeLanguage: Function
}

export const GameItemProvider: React.FC<GameItemProviderProps> = ({
  children,
}) => {
  const [gameItemList, setGameItemList] = useState<GameItem[]>(Games);
  useEffect(() => {
    const fetchGames = async () => {
      const fetchedGames = await readGames();
      // const fetchedGames = Games;
      if (fetchedGames.length > 0) {
        setGameItemList(fetchedGames);
      }
      try {
      } catch (error) {
        console.error("Error fetching games:", error);
      }
    };
    fetchGames();
  }, []);

  return (
    <GameItemListContext.Provider value={gameItemList}>
      {children}
    </GameItemListContext.Provider>
  );
};



const changeLanguage: contextType = {
  language: lenguaje.en,
  changeLanguage: ()=>{}
}

export const languageContext = createContext<contextType>(changeLanguage);



// Proveedor para el idioma
export const LanguageProvider: React.FC<LanguageContextType> = ({
  children,
}) => {
  
  const [language, setLanguage] = useState<lenguaje>(lenguaje.en);
  // Estado para almacenar el idioma seleccionado, con 'en' como valor inicial
const estadoInicial:{language: lenguaje, changeLanguage: Function} = {
  language,
  changeLanguage: (newLanguage: lenguaje)=>{ 
    setLanguage(newLanguage)
  }
}

  return (
    <languageContext.Provider value={estadoInicial}>
      {children}
    </languageContext.Provider>
  );
};
