import { useContext, useEffect, useRef, useState } from "react";
import { NavBar } from "../../components/NavBar/NavBar";
import style from "./registro_correo_style.module.css";
import "../../components/Dialog/Dialog.css";
import { useLocation, useNavigate } from "react-router-dom";
import { languageContext, lenguaje } from "../../Context/Context";
import imageLogo from "../../assets/img/logo-banca-gamer-resize.png";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { validateForm } from "../../utils/Regex";
import { registrarCorreo } from "../../utils/SendMail";
import { Loader } from "../../components/Loader/Loader";

export const RegistroCorreo = () => {
  const { language } = useContext(languageContext);
  const formRef = useRef<HTMLFormElement | null>(null);
  const correoRef = useRef<HTMLInputElement | null>(null);
  const [correo, setCorreo] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailCorrecto, setEmailCorrecto] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const videoFaq = document.getElementById("videoCalculadoras");
    if (location.pathname === "/registro") {
      videoFaq!.style.display = "block";
    }
    return () => {
      if (videoFaq !== null) {
        videoFaq.style.display = "none";
      }
    };
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    const esValido = validateForm(correo);
    if (esValido) {
        await registrarCorreo(
            nombre,
            apellido,
            correo,
            handleClick
        ).then(() =>
          setTimeout(() => {
            navigate("/mail-register");
          }, 700)
        );
      }
  };

  const handleClick = (e: any) => {
    setIsLoading(false);

  };


  return (
      <section className={style.sectionregistrocorreo}>
        <div className={style.container}>
          <h1 className={style.title}>
            Regístrate
            <br />
            Aquí
          </h1>
          <div className={style.divider}></div>
          <div className={style.dialogregistro}>
          <img
            src={imageLogo}
            alt=""
            className="title-logo"
          />
            <form name="user" ref={formRef} onSubmit={handleSubmit}>
              <div className={style.rowregistro}>
                {/* <span className="material-symbols-outlined icon">face</span> */}
                <label htmlFor="name">
                  {language === lenguaje.es ? "Nombre:" : "Name:"}
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                  className={
                    emailCorrecto ? "dialog-input" : "dialog-input error"
                  }
                />
              </div>
              <div className={style.rowregistro}>
                {/* <span className="material-symbols-outlined icon">face</span> */}
                <label htmlFor="apellido">
                  {language === lenguaje.es ? "Apellido:" : "Last Name:"}
                </label>
                <input
                  type="text"
                  name="lastaname"
                  id="lastname"
                  value={apellido}
                  onChange={(e) => setApellido(e.target.value)}
                  className={
                    emailCorrecto ? "dialog-input" : "dialog-input error"
                  }
                />
              </div>
              <div className={style.rowregistro}>
                <label htmlFor="email">
                  {language === lenguaje.es ? "Correo:" : "Email:"}
                </label>
                <input
                  ref={correoRef}
                  type="email"
                  name="email"
                  id="email"
                  value={correo}
                  onChange={(e) => setCorreo(e.target.value)}
                  // onChange={()=>console.log(correoRef?.current?.value)}
                  className={`dialog-input`}
                />
              </div>

              <div className="row">
                <ButtonBancaGamer
                  ruta={""}
                  action={() => {}}
                  label={language === lenguaje.es ? "Enviar" : "Send"}
                  tipo={2}
                ></ButtonBancaGamer>
              </div>
            </form>
          </div>
        </div>
        <Loader isLoading={isLoading} />
      </section>
  );
};
