
import { NavBar } from "../../components/NavBar/NavBar";
import { useContext, useEffect, useState } from "react";
import InputText from "../../components/InputText/InputText";
import { Titular } from "../../components/Titular/Titular";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { Dialog } from "../../components/Dialog/Dialog";
import { genshinImpact } from "../../data/ValorDeItems";
import { useLocation } from "react-router-dom";
import { languageContext, lenguaje } from "../../Context/Context";
export const GenshinImpact = () => {
  const { language } = useContext(languageContext);
  // Estados calculadora
  const [cotizacion, setCotizacion] = useState(0);
  const [tipoBoton, setTipoBoton] = useState(3);
  const [openDialog, setOpendialog] = useState(false);
  // Estados juego
  const [rangoAventura, setRangoAventura] = useState(0);
  const [personajes5Estrellas, setPersonajes5Estrellas] = useState(0);
  const [personajes4Estrellas, setPersonajes4Estrellas] = useState(0);
  const [protogemas, setProtogemas] = useState(0);
  const [destinoEntrelazado, setDestinoEntrelazado] = useState(0);
  const [encuentroDelDestino, setEncuentroDelDestino] = useState(0);
  const [cristales, setCristales] = useState(0);

  const nombreJuego = "Genshin Impact";

  let valorDeCuenta =
    genshinImpact.inicial + (rangoAventura * genshinImpact.rangoAventura) + (personajes5Estrellas * genshinImpact.personajes5Estrellas) + (personajes4Estrellas * genshinImpact.personajes4Estrellas) + (protogemas * genshinImpact.protogemas) + (destinoEntrelazado * genshinImpact.destinoEntrelazado) + (encuentroDelDestino * genshinImpact.encuentroDelDestino) + (cristales * genshinImpact.cristales);

  const handleClick = () => {
    let val: number = valorDeCuenta / 100;
    val > cotizacion
      ? addAnimation(val)
      : val < cotizacion
        ? substracAnimation(val)
        : console.log("no cambiar");

    if (val > 0) {
      setTipoBoton(2);
    } else {
      setTipoBoton(3);
    }
  };

  // animacion de calculo de valores cuando suma
  const addAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result < resultado) {
        result = result + 0.13;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  // animacion de calculo de valores cuando resta
  const substracAnimation = async (resultado: number) => {
    let result = cotizacion;
    const intervalId = setInterval(() => {
      if (result > resultado) {
        result = result - 0.013;
        setCotizacion(result);
      } else {
        clearInterval(intervalId);
        setCotizacion(resultado);
        console.log("Terminado");
      }
    }, 2);
  };
  const enviarCuenta = () => {
    setOpendialog(true);
  };

  const location = useLocation();
  const [showScrollDown, setShowScrollDown] = useState(true);
  useEffect(() => {
    const onScroll = () => {
      const percentageScroll =
        ((window.scrollY / window.innerHeight) * 100) / 4;

      if (percentageScroll <= 75) {
        setShowScrollDown(true);
      } else {
        setShowScrollDown(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    const videosCalculadora = document.getElementById("videoCalculadoras");
    if (location.pathname !== "/" && location.pathname !== "/Faqs") {
      videosCalculadora!.style.display = "block";
    }

    return () => {
      if (videosCalculadora !== null) {
        videosCalculadora.style.display = "none";
        window.removeEventListener("scroll", onScroll);
      }
    };
  });
  return (
    <section className="calculadora calculadora-padding">
      <NavBar />
      <div className="form-container">
        <Titular cotizacion={cotizacion} titular={nombreJuego} />
        <span style={{ height: "60px" }}></span>
        <div className="grid">
          <InputText
            etiqueta={language === lenguaje.es ? "RANGO DE AVENTURA" : "ADVENTURE RANK"}
            valor={rangoAventura}
            setValue={setRangoAventura}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "PERSONAJES 5 ESTRELLAS" : "5-STAR CHARACTERS AMOUNT"}
            valor={personajes5Estrellas}
            setValue={setPersonajes5Estrellas}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "PERSONAJES 4 ESTRELLAS" : "4-STAR CHARACTERS AMOUNT"}
            valor={personajes4Estrellas}
            setValue={setPersonajes4Estrellas}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "PROTOGEMAS" : "PRIMOGEMS"}
            valor={protogemas}
            setValue={setProtogemas}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "DESTINO ENTRELAZADO" : "INTERWINED FATE"}
            valor={destinoEntrelazado}
            setValue={setDestinoEntrelazado}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "ENCUENTRO DEL DESTINO" : "ACQUAINT FATE"}
            valor={encuentroDelDestino}
            setValue={setEncuentroDelDestino}
            aumento={1}
          />
          <InputText
            etiqueta={language === lenguaje.es ? "CRISTALES" : "CRYSTALS"}
            valor={cristales}
            setValue={setCristales}
            aumento={1}
          />
        </div>
        <div className="calculadora-buttons">
          <ButtonBancaGamer
            ruta=""
            action={handleClick}
            label={language === lenguaje.es ? "CALCULAR VALOR" : "ESTIMATE NOW"}
            tipo={2}
          />
          <ButtonBancaGamer
            ruta=""
            action={enviarCuenta}
            label={language === lenguaje.es ? "ENVIAR" : "SEND"}
            tipo={tipoBoton}
          />
        </div>
        <div className="footer">COPYRIGHT © 2024 BANCO DEL PACÍFICO</div>
      </div>
      <Dialog openDialog={openDialog} closeDialog={setOpendialog} juego={nombreJuego} valor={cotizacion} />
    </section>
  );
}