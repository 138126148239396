import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GameItemListContext } from "../../Context/Context";
import "./GamesSettings.css";
import { NavBar } from "../../components/NavBar/NavBar";
import { GameInput } from "./GameInput";
import imageLogo from "../../assets/img/logo-banca-gamer-resize.png";
import { getSubscribers } from "../../utils/SendMail";
import { subscriptor } from "../../utils/Subscriptor";
import { Loader } from "../../components/Loader/Loader";
import { ButtonBancaGamer } from "../../components/Button/Button";
import { writeXLSX, utils, JSON2SheetOpts, writeFile } from "xlsx";

// 3hDvQeg1!!
enum menus {
  valores,
  subscripciones,
}

export const GameSettings = () => {
  const gameItemList = useContext(GameItemListContext);
  const valores = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();
  const [read, setRead] = useState(false);
  const [menu, setMenu] = useState(menus.valores);
  const [subscriptores, setSubscriptores] = useState<subscriptor[]>([]);
  const [loadQuery, setLoadQuery] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (!user?.isAnonymous) {
        setRead(true);
      } else {
        if (user === null) {
          navigate("/Paradais");
        }
      }
      const videoContainer = document.getElementById("videoContainer");
      if (location.pathname === "/GamesSettings") {
        console.log(videoContainer);
        videoContainer!.style.display = "none";
      }
      return () => {
        if (videoContainer !== null) {
          videoContainer.style.display = "block";
        }
      };
    });
  });

  async function handleSubscribers(destination: menus) {
    setLoadQuery(true);
    setMenu(menus.subscripciones);
    const subscriptores = await getSubscribers();
    setSubscriptores(subscriptores);
    setLoadQuery(false);
  }
  function handleExport() {
    var book = utils.book_new();
    var data = utils.json_to_sheet(subscriptores);
    utils.book_append_sheet(book, data, "Correos Banca Gamer");
    writeFile(book, "Banca Gamer - Subscriptores.xlsx", { bookType: "xlsx" });
  }
  return !read ? null : (
    <div className="layout-game-options">
      {/* <NavBar /> */}
      <div className="navigation">
        <div>
          <img src={imageLogo} alt="" className="title-logo" />
        </div>
        <button onClick={() => setMenu(menus.valores)}>
          <span className="material-symbols-outlined">attach_money</span>
          <span>Valores</span>
        </button>
        <button onClick={() => handleSubscribers(menus.subscripciones)}>
          <span className="material-symbols-outlined">book_5</span>
          <span>Subscripciones</span>
        </button>
      </div>
      {menu === menus.valores ? (
        <div className="gsSection">
          <h1>Promedios de juegos</h1>
          <div className="grid">
            {gameItemList.map((game, index) => {
              // console.log(game);
              // const [juego, setJuego] = useState(0);
              return <GameInput juego={game} key={index} />;
            })}
          </div>
          <div>Juego registrado con exito</div>
        </div>
      ) : (
        <div className="gsSection">
          <Loader isLoading={loadQuery} />
          <div className="descargas-bar">
            <h2>Subscriptores de correo</h2>
            <ButtonBancaGamer
              ruta={""}
              action={() => handleExport()}
              label={"Descargar"}
              tipo={2}
            ></ButtonBancaGamer>
          </div>
          <table>
            <thead>
              <tr>
                <th>Correo</th>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Registro</th>
              </tr>
            </thead>
            <tbody>
              {subscriptores.map((sub, index) => {
                return (
                  <tr key={index}>
                    <td>{sub.correo}</td>
                    <td>{sub.nombre}</td>
                    <td>{sub.apellido}</td>
                    <td>{sub.registro}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
